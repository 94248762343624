import { LoadingButton as Button } from '@mui/lab';
import FormHelperText from '@mui/material/FormHelperText';
import Link from '@mui/material/Link';
import { Box, styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { getAuth } from 'firebase/auth';
import { useFormik } from 'formik';
import { parsePhoneNumber } from 'libphonenumber-js';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import 'react-phone-number-input/style.css';
import * as yup from 'yup';

import { Input } from '../../components/Input';
import { PhoneNumberInput } from '../../components/PhoneNumberInput';
import { withRoot } from '../../components/Root';
import { firebaseApp } from '../../firebase-app';
import { usePrevious } from '../../hooks/previous';
import { useFragmentContext } from '../../hooks/useFragmentContext';
import { Checkout as Layout } from '../../layouts/Checkout';
import { getErrorContent } from '../../utils/errors';
import { signInUser } from './async';
import { createRedirectFunc, sessionLogin } from './util';

const Logo = styled('img')(() => ({
    width: 140,
    height: 'auto',
}));

const useStyles = makeStyles(theme => ({
    '@global': {
        '.PhoneInputCountry': {
            marginRight: 8,
            verticalAlign: 'middle',
            marginTop: -8,
            fontSize: 18,
        },
    },
    login: {
        flex: 1,
        '& form': {
            flex: 1,
        },
    },
    title: {
        color: '#FFF',
        lineHeight: '1.625!important',
        fontSize: '1.8rem',
        marginBottom: 0,
        marginTop: 0,
        fontWeight: 600,
        paddingBottom: '2rem',
    },
    errorText: {
        fontSize: '1.45rem',
        fontWeight: 'normal',
        marginBottom: theme.spacing(),
        textAlign: 'center',
    },
    controls: {
        marginBottom: theme.spacing(),
        '& input': {
            fontSize: 14,
        },
    },
    formActions: {
        marginTop: theme.spacing(3),
        background: 'none',
    },
    btnWrapper: {
        position: 'relative',
    },
    loginButton: {
        padding: '1.6rem',
        borderRadius: '.8rem',
        textTransform: 'uppercase',
        background: '#ff6c2f',
        fontSize: 16,
        color: '#FFF',
        fontWeight: 600,
        '&:hover': {
            background: '#ff6c2f',
        },
    },
    btnDisabled: {
        backgroundColor: '#cccccc!important',
        color: '#666666!important',
    },
    error: {
        fontSize: '1.45rem',
        textAlign: 'center',
    },
    loginMeta: {
        textAlign: 'center',
        marginTop: theme.spacing(3),
    },
    weightyLink: {
        paddingLeft: theme.spacing(0.5),
        color: '#ff6c2f',
        fontWeight: 600,
        '&:hover': {
            textDecoration: 'none',
            cursor: 'pointer',
        },
    },
    metaText: {
        color: '#868788',
        paddingLeft: theme.spacing(),
    },
    signInPlaceholder: {
        color: '#FFF',
        fontSize: 18,
        textAlign: 'center',
    },
}));

const defaultState = {
    user_id: '',
    password: '',
};

function Login(props) {
    const { history, location } = props;
    const classes = useStyles();
    const [submitMessage, setSubmitMessage] = useState(null);
    const { user } = useFragmentContext();
    const isAuthenticated = !!user;
    const wasAuthenticated = usePrevious(isAuthenticated);

    const search = queryString.parse(location.search);
    const { countryCode } = useFragmentContext();
    const [preferredCountry, setPreferredCountryCode] = useState(countryCode || 'GH');

    const hasCustomSignInToken = Boolean(search.u_token);

    useEffect(() => {
        if (!wasAuthenticated && isAuthenticated) {
            history.replace('/');
        }
    }, [wasAuthenticated, isAuthenticated, history]);

    useEffect(() => {
        if (hasCustomSignInToken) {
            const auth = getAuth(firebaseApp);
            auth.setPersistence('NONE')
                .then(() => auth.signInWithCustomToken(search.u_token))
                .then(sessionLogin)
                .then(
                    body =>
                        new Promise((resolve, reject) => {
                            // eslint-disable-next-line promise/no-nesting
                            auth.signOut()
                                .then(() => resolve(body))
                                .catch(err => reject(err));
                        }),
                )
                .then(createRedirectFunc(location))
                .catch(err => {
                    setSubmitMessage(err.message);
                });
        }
    }, [hasCustomSignInToken, search, location]);

    const login = async credentials => {
        const isPhone = /^\d+$/i.test(credentials.user_id);
        const cc = preferredCountry;
        if (isPhone && !credentials.user_id.startsWith(cc)) {
            const phoneNumber = parsePhoneNumber(credentials.user_id, cc);
            // eslint-disable-next-line no-param-reassign
            credentials.user_id = phoneNumber.number;
        } else {
            // eslint-disable-next-line no-param-reassign
            credentials.user_id = credentials.user_id.toLowerCase();
        }

        signInUser(credentials, (err, response) => {
            if (err) {
                if (Object.hasOwnProperty.call(err, 'errors')) {
                    setSubmitMessage(getErrorContent(err.errors));
                    return;
                }

                setSubmitMessage(getErrorContent(err));
                return;
            }

            if (response && response.success) {
                const redirect = createRedirectFunc(location);
                redirect();
            }
        });
    };

    const formik = useFormik({
        initialValues: defaultState,
        validateOnChange: false,
        validationSchema: yup.object().shape({
            user_id: yup.string().required('User ID is required'),
            password: yup.string().required(),
        }),
        onSubmit: login,
    });

    const { values, errors, handleChange } = formik;
    const onChange = (name, value) => handleChange({ target: { name, value } });

    return (
        <Layout>
            <section className={classes.login}>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'start',
                        marginBottom: 2,
                    }}
                >
                    <Logo src="/wi-flix.png" />
                </Box>

                {hasCustomSignInToken && (
                    <Typography className={classes.signInPlaceholder} variant="h5">
                        Please wait...
                    </Typography>
                )}
                {!hasCustomSignInToken && (
                    <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
                        <Typography className={classes.title} variant="h3">
                            Sign in
                        </Typography>

                        <div style={{ marginBottom: 16 }} className={classes.controls}>
                            <PhoneNumberInput
                                name="user_id"
                                error={errors.user_id}
                                value={values.user_id}
                                placeholder="Enter your email or phone number"
                                onChange={onChange}
                                countryCode={preferredCountry}
                                setCountryCode={setPreferredCountryCode}
                            />
                        </div>

                        <div className={classes.controls}>
                            <Input
                                variant="outlined"
                                name="password"
                                value={values.password}
                                placeholder="Enter password"
                                type="password"
                                autoComplete="new-password"
                                fullWidth
                                error={errors.password}
                                onChange={onChange}
                            />
                        </div>

                        <fieldset className={classes.loginMeta}>
                            <Typography
                                className={classes.metaText}
                                gutterBottom
                                variant="subtitle2"
                            >
                                Don’t have an account?
                                <Link
                                    className={classes.weightyLink}
                                    style={{
                                        marginTop: 7,
                                    }}
                                    href="http://onboarding.wi-flix.com"
                                >
                                    Sign Up
                                </Link>
                            </Typography>
                        </fieldset>

                        {Boolean(submitMessage) && (
                            <FormHelperText
                                error
                                classes={{
                                    root: classes.errorText,
                                }}
                            >
                                {submitMessage}
                            </FormHelperText>
                        )}

                        <fieldset className={classes.formActions}>
                            <div className={classes.btnWrapper}>
                                <Button
                                    loading={formik.isSubmitting}
                                    disabled={formik.isSubmitting}
                                    classes={{
                                        root: classes.loginButton,
                                        disabled: classes.btnDisabled,
                                    }}
                                    fullWidth
                                    variant="contained"
                                    type="submit"
                                >
                                    Log In
                                </Button>
                            </div>
                        </fieldset>

                        <fieldset className={classes.loginMeta}>
                            <Typography className={classes.metaText} variant="subtitle2">
                                <Link
                                    className={classes.weightyLink}
                                    style={{
                                        marginTop: 7,
                                    }}
                                    href="/forgot-password"
                                >
                                    Forgot Password
                                </Link>
                            </Typography>
                        </fieldset>
                    </form>
                )}
            </section>
        </Layout>
    );
}

Login.getInitialProps = async () => {
    await Promise.resolve();
};

Login.getChunkName = () => {
    return 'Login';
};

const LoginWithFirebaseRoute = withRoot(Login);

export { LoginWithFirebaseRoute as Login };
