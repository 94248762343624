export function getBrowserCookies(): Record<string, string> {
    const cookies: Record<string, string> = {};
    const cookieString = document.cookie;

    const cookieArray = cookieString.split(';');

    cookieArray.forEach(cookie => {
        const [name, value] = cookie.trim().split('=');
        if (name) {
            cookies[name] = value ? decodeURIComponent(value) : '';
        }
    });

    return cookies;
}
